<!--
    参数：
        disabled:是否禁选
        auto:是否进来就默认跑第一个参数
        isClear:清空当前所选的数据
-->

<template>
  <div>
      <Cascader transfer @on-change="changeCasder" :load-data="loadData" change-on-select style=" width: 200px;margin-right:10px" :data="CascaderList" v-model="CascaderId" placeholder="请选择区/街道/社区" :disabled="disabled"></Cascader>
  </div>
</template>

<script>
export default {
    props: {
        disabled: {
            type: Boolean,
            default: () => {
                return false;
            }
        },
        auto: {
            type: Boolean,
            default: () => {
                return false;
            }
        },
        isClear:{
            type:Boolean,
            default:() =>{
                return false
            }
        }
    },
    data(){
        return{
            CascaderList:[],
            CascaderId:[],
            isNum:0
        }
    },
    created(){
        this.getCascader()
    },
    methods:{
        changeCasder(val){
            this.$emit('changeCasder',val)
        },
        // 获取级联
        getCascader(){
            this.$get('/orgzz/pc/organization/selectCascadeDataScope',{
                custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
                oemCode:parent.vue.oemInfo.oemCode,
                orgCode:'4401',
                orgCodeSelf:parent.vue.loginInfo.userinfo.orgCode,
            }).then(res=>{
                if(res.code == 200 && res.dataList.length != 0){
                this.CascaderList = this.forMatterTree(res.dataList)
                    this.CascaderList.map(item =>{
                        item.loading = false
                    })
                    if(this.CascaderList.length != 0 && this.auto == true){
                        this.CascaderId.push(this.CascaderList[0].value)
                        this.$emit('changeCasder',this.CascaderId)
                    }
                }
            })
        },
        forMatterTree(data) {
            for (var i = 0; i < data.length; i++) {
                data[i].label = data[i].orgName;
                data[i].value = data[i].orgCode;
                if (data[i].children && data[i].children != null && data[i].children.length > 0 ) {
                    this.forMatterTree(data[i].children);
                }
            }
            return data;
        },
        // 异步加载级联
        loadData (item, callback) {
            item.loading = true
            let params = {
                custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
                oemCode:parent.vue.oemInfo.oemCode,
                orgCode:item.orgCode,
                orgCodeSelf:parent.vue.loginInfo.userinfo.orgCode,
            };
            this.$get('/orgzz/pc/organization/selectCascadeDataScope',{
                ...params
            }).then(res=>{
                if(res.code == 200 && res.dataList.length != 0){
                let arr = this.forMatterTree(res.dataList)
                arr.map(items =>{
                    if(items.orgLevel == 4){
                    items.loading = false
                    }
                })
                item.loading = false
                item.children = arr
                callback(item.children);
                if(this.isNum == 0 && item.children.length != 0 && this.auto == true){
                    this.CascaderId.push(item.children[0].value)
                    this.isNum = 1
                    this.$emit('changeCasder',this.CascaderId)
                    return
                }else if(this.isNum == 1 && item.children.length != 0 && this.auto == true){
                    this.CascaderId.push(item.children[0].value)
                    this.isNum = 0
                    this.$emit('changeCasder',this.CascaderId)
                }
                }else{
                callback([]);
                }
            })
        },
    },
    watch:{
        isClear(val){
                this.CascaderId = []
                this.$emit('changeCasder',this.CascaderId)

        }
    }
}
</script>

<style>

</style>